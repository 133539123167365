export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'
export const REQUEST_HEADER_API_KEY = 'x-api-key'
export const TASK_QUEUE_API_KEY = 'Bearer LkahsjdhjHAWURyuihASNDMnzmxcASlkasdASjkj12009523kkjhaskjdh247777'
export const VM_HOST_SERVER = 'workers.blendexpo.com'
export const APPENGINE_HOST_SERVER = 'blendexpo-workers-v6.uw.r.appspot.com'
export const SWW = 'There’s something wrong, please try again.'

// Your web app's Firebase configuration
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAdWflcnipKwZnOM1MTFZUCnyW9K3WLk4c",
  authDomain: "blendexpo-workers-v6.firebaseapp.com",
  projectId: "blendexpo-workers-v6",
  storageBucket: "blendexpo-workers-v6.appspot.com",
  messagingSenderId: "1063288121550",
  appId: "1:1063288121550:web:e241876015b8629361089f"
};
